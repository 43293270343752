@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body {
  background: #00063d;
}

.hero-section a {
  background: transparent;
}

.formOverlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.267);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepBar {
  padding-bottom: 10px;
}

.swiper-creative .swiper-slide {
  overflow: unset !important;
}

.swiper-3d .swiper-slide-shadow {
  background-color: transparent !important;
}

.SeekSectionInstBg {
  background: linear-gradient(180deg, #ffffff 0%, #F2ECFA 100%);
}

.custom-active-slide {
  transform: scale(1);
  opacity: 1;
  z-index: 3 !important;
  position: relative !important;
  transition: transform 0.3s;
}

.custom-adjacent-slide {
  transform: scale(0.8);
  z-index: 2;
  position: relative;
  transition: transform 0.3s;
}

.custom-far-slide {
  transform: scale(0.6);
  z-index: 1;
  position: relative;
  transition: transform 0.3s;
}

.custom-far-slide::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.custom-adjacent-slide:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.custom-swiper-slide-active {
  z-index: 3 !important;
  width: 300px !important;
}

.custom-swiper-slide-prev {
  z-index: 2 !important;
  width: 250px !important;
}

.custom-swiper-slide-next {
  z-index: 2 !important;
  width: 250px !important;
  margin-left: -100px;
}

.swiper-3d .swiper-slide-shadow-left {
  border-radius: 24px !important;
  background-image: linear-gradient(to left, rgb(0, 0, 0), rgba(0, 0, 0, 0.505)) !important;
  width: 300px !important;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
}

.swiper-slide-shadow-coverflow {
  background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.505)) !important;

  border-radius: 24px !important;
  width: 300px !important;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  
}
.block-white {
  background: #ffffff;
  border-radius: 0px 175.606px 0px 0px;
  width: 50%;
  height: 100px;
}
